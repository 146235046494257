import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selectors, useSelector } from '../../store/createPortalStore';
import VoucherForm from '../ui/voucher/VoucherForm';
import * as actions from '../../actions/user';
import { user as userSelector } from 'selectors/user';
import { setDocumentTitle } from '../../actions/documentTitle';
import { GET_USER_DETAILS } from '../../ducks/subscription';
import { withFeatureFlags } from 'components/context/withFeatureFlags';

const Voucher = (props) => {
  // Destructure
  const user = useSelector(userSelector);
  const location = useLocation();
  const {
    stepper: stepperSelector,
    voucher: voucherSelector,
    loading: loadingSelector,
  } = selectors;
  // Selectors
  const voucher = useSelector(voucherSelector.voucher);
  const stepper = useSelector(stepperSelector.stepper);
  const isUserGetDetailsFinished = useSelector(
    loadingSelector.isSuccess({ action: GET_USER_DETAILS }),
  );
  // Hooks
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDocumentTitle('Redeem your code'));
    // eslint-disable-next-line
  }, []);

  const {
    initObserver,
    loggedIn,
    inProgress,
    loginFailure,
    validateEmail,
    createUserByVoucher,
    subscriptionVoucher,
    subscriptionUserCreate,
  } = user;

  const {
    subscriptionUserCreate: actionSubscriptionUserCreate,
    loginUser: actionUnsetLoginUser,
    subscriptionVoucher: actionSubscriptionVoucher,
    createUserByVoucher: actionCreateUserByVoucher,
    validateEmail: actionValidateEmail,
    toggleAuthInProgress: actionToggleAuthInProgress,
  } = actions;

  return (
    <VoucherForm
      user={user}
      location={location}
      isUserGetDetailsFinished={isUserGetDetailsFinished}
      initObserver={initObserver}
      loggedIn={loggedIn}
      inProgress={inProgress}
      loginFailure={loginFailure}
      actionUnsetLoginUser={actionUnsetLoginUser}
      actionToggleAuthInProgress={actionToggleAuthInProgress}
      userActions={actions}
      actionValidateEmail={actionValidateEmail}
      validateEmail={validateEmail}
      actionCreateUserByVoucher={actionCreateUserByVoucher}
      createUserByVoucher={createUserByVoucher}
      actionSubscriptionVoucher={actionSubscriptionVoucher}
      subscriptionVoucher={subscriptionVoucher}
      subscriptionUserCreate={subscriptionUserCreate}
      actionSubscriptionUserCreate={actionSubscriptionUserCreate}
      voucher={voucher}
      stepper={stepper}
      {...props}
    />
  );
};

export default withFeatureFlags(Voucher);
