import _ from 'lodash';
import * as types from '../constants/actionTypes';
import API from '@aws-amplify/api';
import pageMapping from '../middleware/pageMapping';
import defaultConfig from 'constants/defaultConfig';

export function loadPage(id, appManagerKey, getFeatureFlags) {
  const customWidgetConfig = getFeatureFlags
    ? getFeatureFlags('CUSTOM_WIDGET')
    : defaultConfig.features?.CUSTOM_WIDGET;

  const promotionalPlacementsConfig = getFeatureFlags
    ? getFeatureFlags(`PROMOTIONAL_PLACEMENTS_CONFIG`)
    : defaultConfig.features?.PROMOTIONAL_PLACEMENTS_CONFIG;
  const isPromotionalPlacementsEnable = promotionalPlacementsConfig?.enabled;

  return function(dispatch) {
    dispatch({ type: types.GET_PAGE_INPROGRESS, page: { id: id } });

    const fetchMetadataPage = () => {
      if (!id) {
        return;
      }
      return API.get('fe-api-dev-metadataGetPage', `/pages/${id}`, {})
        .then((page) => {
          if (page && page.id) {
            dispatch({
              type: types.GET_PAGE_SUCCESS,
              page: pageMapping({
                page,
                id,
                customWidgetConfig,
                isPromotionalPlacementsEnable,
              }),
            });
          } else {
            dispatch({ type: types.GET_PAGE_FAILURE, page: { id: id } });
          }
        })
        .catch((error) => {
          dispatch({ type: types.GET_PAGE_FAILURE, page: { id: id } });
        });
    };

    const api = 'fe-api-dev-appManagerGetPageV2';
    if (!appManagerKey) {
      return;
    }
    return API.get(api, `/apps/web/pages/${appManagerKey}`, {})
      .then((page) => {
        const success = _.get(page, 'success');
        const dataPanels = _.get(page, 'data.panels');

        if (success && !_.isEmpty(dataPanels)) {
          dispatch({
            type: types.GET_PAGE_SUCCESS,
            page: pageMapping({
              page,
              id,
              customWidgetConfig,
              isPromotionalPlacementsEnable,
            }),
          });
        } else {
          fetchMetadataPage();
        }
      })
      .catch((error) => {
        fetchMetadataPage();
      });
  };
}

export const loadPageV3 = (appManagerKey, pageType) => {
  return async (dispatch, getState) => {
    return API.get(
      'fe-api-dev-appManagerGetPageV3',
      `/apps/web/pages/${appManagerKey}`,
      {},
    )
      .then((page) => {
        const success = _.get(page, 'success');
        const dataPanels = _.get(page, 'data.panels');

        if (success && pageType) {
          const groupKey = _.get(page, 'data.group.key', '');

          if (pageType !== groupKey) {
            dispatch({
              type: types.GET_PAGE_FAILURE,
              page: {
                id: appManagerKey,
              },
            });
            return;
          }
        }

        if (success && !_.isEmpty(dataPanels)) {
          const pageData = pageMapping({
            page,
            id: appManagerKey,
            customWidgetConfig: [],
            isPromotionalPlacementsEnable: true,
          });

          dispatch({
            type: types.GET_PAGE_SUCCESS,
            page: {
              ...pageData,
              type: pageType,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PAGE_FAILURE,
          page: {
            id: appManagerKey,
          },
        });
      });
  };
};
