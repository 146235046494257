import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import cookie from 'react-cookies';

import LeftScrollControl from './LeftScrollControl';
import RightScrollControl from './RightScrollControl';
import ScoreProtection from './ScoreProtection';
import CompetitionGroup from './CompetitionGroup';
//import { debounce } from '../../../../utils/debounce';

smoothscroll.polyfill();

/** SaaGBarV2 component. Each child node, for example a ```<div>``` will be an accordion item with title passed on as ```label``` prop to the child. */
class SaaGBarV2 extends Component {
  constructor(props) {
    super(props);

    this.tempDisableTouchScroll = false;

    this.openSectionStatus = {
      // firstUWC: true,
      firstEPL: true,
      firstUCL: false,
      firstUEL: false,
      firstECQ2020: false,
      firstWSL: false,
      firstJPJL: false,
      firstEC2020: false,
      firstUNL: false,
      firstWQE: false,
      firstCOA: false,
      firstDFB: false,
      firstEUC: false,
      firstNWSL: false,
      // firstWWC: false,
      // firstFCW: false,
      // firstFCWC: false,
      // firstKL: false,
      // firstUSC: false,
      // lastUWC: false,
      lastEPL: false,
      lastUCL: false,
      lastUEL: false,
      lastECQ2020: false,
      lastWSL: false,
      lastJPJL: false,
      lastEC2020: false,
      lastUNL: false,
      lastWQE: false,
      lastCOA: false,
      lastDFB: false,
      lastEUC: false,
      lastNWSL: false,
      // lastWWC: false,
      // lastFCW: false,
      // lastFCWC: false,
      // lastKL: false,
      // lastUSC: false,
    };

    const openSections = {
      // UWC: true,
      EPL: true,
      UCL: true,
      UEL: true,
      ECQ2020: true,
      WSL: true,
      JPJL: true,
      // EC2020: true,
      UNL: true,
      WQE: true,
      COA: true,
      DFB: true,
      WWC: true,
      EUC: true,
      NWSL: true,
      // FCW: true,
      // FCWC: true,
      // KL: true,
      // USC: true,
    };

    this.lastScrollLeft = 0;

    this.props.children.forEach((child) => {
      if (child && child.props && child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = {
      ScoreProtectionDisabled: cookie.load('scoreprotection') === 'true',
      openSections,
      rightEndReached: false,
      leftEndReached: true,
    };
  }

  componentDidMount() {
    const scrollableSection = document.querySelector(
      '.saag-scrollable-section',
    );
    if (scrollableSection) {
      scrollableSection.addEventListener('scroll', this.touchScroll);
    }
    scrollableSection.scrollLeft = 0;
  }

  touchScroll = () => {
    if (this.tempDisableTouchScroll === true) {
      return;
    }

    let rightClasses = [
      'pin-r',
      'pin-r-50',
      'pin-r-100',
      'pin-r-150',
      'pin-r-200',
      'pin-r-250',
      'pin-r-300',
      'pin-r-350',
      'pin-r-400',
    ];
    let leftClasses = [
      'pin-l',
      'pin-l-50',
      'pin-l-100',
      'pin-l-150',
      'pin-l-200',
      'pin-l-250',
      'pin-l-300',
      'pin-l-350',
      'pin-l-400',
    ];
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const scrollStep = 136;

    let championship;
    let championshipPositionValidity = [];
    const fixedLeftPostions = [
      '32px',
      '82px',
      '132px',
      '182px',
      '232px',
      '282px',
      '332px',
      '382px',
      '432px',
    ];

    const scrollableSection = document.querySelector(
      '.saag-scrollable-section',
    );
    const stickyHeaders = [...document.querySelectorAll('.sticky-header')];

    if (scrollableSection.scrollLeft >= this.lastScrollLeft) {
      const competitionOrder = _.get(this.props, 'competitionOrder', []);
      let validChampionships = [...competitionOrder];
      championshipPositionValidity = [];

      for (let i = 0; i < validChampionships.length; i++) {
        const querySelector = `.${validChampionships[i]}-sticky-header.${
          rightClasses[validChampionships.length - i - 1]
        }`;
        championshipPositionValidity.push(
          document.querySelector(querySelector),
        );
      }

      for (let j = 0; j < validChampionships.length; j++) {
        championship = championshipPositionValidity[j];
        if (
          championship &&
          this.openSectionStatus[`first${validChampionships[j]}`]
        ) {
          if (!isSafari || isEdge || isFirefox) {
            championship.classList.add(leftClasses[j - 1]);
            championship.classList.remove(
              rightClasses[validChampionships.length - j - 1],
            );
          }
          break;
        }
      }

      if (isSafari) {
        if (
          stickyHeaders[1] &&
          stickyHeaders[1].getBoundingClientRect().left <= 110
        ) {
          stickyHeaders[1].classList.remove('sticky');
          stickyHeaders[1].classList.add('fixed');
          stickyHeaders[1].style.left = fixedLeftPostions[1];
        }

        if (
          stickyHeaders[2] &&
          stickyHeaders[2].getBoundingClientRect().left <= 182
        ) {
          stickyHeaders[2].classList.remove('sticky');
          stickyHeaders[2].classList.add('fixed');
          stickyHeaders[2].style.left = fixedLeftPostions[2];
        }

        if (
          stickyHeaders[3] &&
          stickyHeaders[3].getBoundingClientRect().left <= 210
        ) {
          stickyHeaders[3].classList.remove('sticky');
          stickyHeaders[3].classList.add('fixed');
          stickyHeaders[3].style.left = fixedLeftPostions[3];
        }

        if (
          stickyHeaders[4] &&
          stickyHeaders[4].getBoundingClientRect().left <= 318
        ) {
          stickyHeaders[4].classList.remove('sticky');
          stickyHeaders[4].classList.add('fixed');
          stickyHeaders[4].style.left = fixedLeftPostions[4];
        }

        if (
          stickyHeaders[5] &&
          stickyHeaders[5].getBoundingClientRect().left <= 362
        ) {
          stickyHeaders[5].classList.remove('sticky');
          stickyHeaders[5].classList.add('fixed');
          stickyHeaders[5].style.left = fixedLeftPostions[5];
        }

        if (
          stickyHeaders[6] &&
          stickyHeaders[6].getBoundingClientRect().left <= 406
        ) {
          stickyHeaders[6].classList.remove('sticky');
          stickyHeaders[6].classList.add('fixed');
          stickyHeaders[6].style.left = fixedLeftPostions[6];
        }

        if (
          stickyHeaders[7] &&
          stickyHeaders[7].getBoundingClientRect().left <= 450
        ) {
          stickyHeaders[7].classList.remove('sticky');
          stickyHeaders[7].classList.add('fixed');
          stickyHeaders[7].style.left = fixedLeftPostions[7];
        }

        if (
          stickyHeaders[8] &&
          stickyHeaders[8].getBoundingClientRect().left <= 494
        ) {
          stickyHeaders[8].classList.remove('sticky');
          stickyHeaders[8].classList.add('fixed');
          stickyHeaders[8].style.left = fixedLeftPostions[8];
          scrollableSection.scrollLeft =
            scrollableSection.scrollLeft + scrollStep;
        }
      } else if (isEdge) {
        if (
          stickyHeaders[1] &&
          stickyHeaders[1].getBoundingClientRect().left <= 110
        ) {
          stickyHeaders[1].classList.remove('sticky');
          stickyHeaders[1].classList.add('fixed');
          stickyHeaders[1].style.left = fixedLeftPostions[1];
        }

        if (
          stickyHeaders[2] &&
          stickyHeaders[2].getBoundingClientRect().left <= 182
        ) {
          stickyHeaders[2].classList.remove('sticky');
          stickyHeaders[2].classList.add('fixed');
          stickyHeaders[2].style.left = fixedLeftPostions[2];
        }

        if (
          stickyHeaders[3] &&
          stickyHeaders[3].getBoundingClientRect().left <= 210
        ) {
          stickyHeaders[3].classList.remove('sticky');
          stickyHeaders[3].classList.add('fixed');
          stickyHeaders[3].style.left = fixedLeftPostions[3];
        }

        if (
          stickyHeaders[4] &&
          stickyHeaders[4].getBoundingClientRect().left <= 318
        ) {
          stickyHeaders[4].classList.remove('sticky');
          stickyHeaders[4].classList.add('fixed');
          stickyHeaders[4].style.left = fixedLeftPostions[4];
        }

        if (
          stickyHeaders[5] &&
          stickyHeaders[5].getBoundingClientRect().left <= 362
        ) {
          stickyHeaders[5].classList.remove('sticky');
          stickyHeaders[5].classList.add('fixed');
          stickyHeaders[5].style.left = fixedLeftPostions[5];
        }

        if (
          stickyHeaders[6] &&
          stickyHeaders[6].getBoundingClientRect().left <= 406
        ) {
          stickyHeaders[6].classList.remove('sticky');
          stickyHeaders[6].classList.add('fixed');
          stickyHeaders[6].style.left = fixedLeftPostions[6];
        }

        if (
          stickyHeaders[7] &&
          stickyHeaders[7].getBoundingClientRect().left <= 450
        ) {
          stickyHeaders[7].classList.remove('sticky');
          stickyHeaders[7].classList.add('fixed');
          stickyHeaders[7].style.left = fixedLeftPostions[7];
        }

        if (
          stickyHeaders[8] &&
          stickyHeaders[8].getBoundingClientRect().left <= 494
        ) {
          stickyHeaders[8].classList.remove('sticky');
          stickyHeaders[8].classList.add('fixed');
          stickyHeaders[8].style.left = fixedLeftPostions[8];
          scrollableSection.scrollLeft =
            scrollableSection.scrollLeft + scrollStep;
        }
      } else if (isFirefox) {
        if (
          stickyHeaders[1] &&
          stickyHeaders[1].getBoundingClientRect().left <= 110
        ) {
          stickyHeaders[1].classList.remove('sticky');
          stickyHeaders[1].classList.add('fixed');
          stickyHeaders[1].style.left = fixedLeftPostions[1];
        }

        if (
          stickyHeaders[2] &&
          stickyHeaders[2].getBoundingClientRect().left <= 182
        ) {
          stickyHeaders[2].classList.remove('sticky');
          stickyHeaders[2].classList.add('fixed');
          stickyHeaders[2].style.left = fixedLeftPostions[2];
        }

        if (
          stickyHeaders[3] &&
          stickyHeaders[3].getBoundingClientRect().left <= 210
        ) {
          stickyHeaders[3].classList.remove('sticky');
          stickyHeaders[3].classList.add('fixed');
          stickyHeaders[3].style.left = fixedLeftPostions[3];
        }

        if (
          stickyHeaders[4] &&
          stickyHeaders[4].getBoundingClientRect().left <= 318
        ) {
          stickyHeaders[4].classList.remove('sticky');
          stickyHeaders[4].classList.add('fixed');
          stickyHeaders[4].style.left = fixedLeftPostions[4];
        }

        if (
          stickyHeaders[5] &&
          stickyHeaders[5].getBoundingClientRect().left <= 362
        ) {
          stickyHeaders[5].classList.remove('sticky');
          stickyHeaders[5].classList.add('fixed');
          stickyHeaders[5].style.left = fixedLeftPostions[5];
        }

        if (
          stickyHeaders[6] &&
          stickyHeaders[6].getBoundingClientRect().left <= 406
        ) {
          stickyHeaders[6].classList.remove('sticky');
          stickyHeaders[6].classList.add('fixed');
          stickyHeaders[6].style.left = fixedLeftPostions[6];
        }

        if (
          stickyHeaders[7] &&
          stickyHeaders[7].getBoundingClientRect().left <= 450
        ) {
          stickyHeaders[7].classList.remove('sticky');
          stickyHeaders[7].classList.add('fixed');
          stickyHeaders[7].style.left = fixedLeftPostions[7];
        }

        if (
          stickyHeaders[8] &&
          stickyHeaders[8].getBoundingClientRect().left <= 494
        ) {
          stickyHeaders[8].classList.remove('sticky');
          stickyHeaders[8].classList.add('fixed');
          stickyHeaders[8].style.left = fixedLeftPostions[8];
          scrollableSection.scrollLeft =
            scrollableSection.scrollLeft + scrollStep;
        }
      }
      this.lastScrollLeft = scrollableSection.scrollLeft;

      if (
        scrollableSection.scrollLeft >=
        scrollableSection.scrollWidth - scrollableSection.clientWidth
      ) {
        this.setState({
          rightEndReached: true,
        });
      } else {
        this.setState({
          rightEndReached: false,
        });
      }

      if (scrollableSection.scrollLeft > 0) {
        this.setState({
          leftEndReached: false,
        });
      } else {
        this.setState({
          leftEndReached: true,
        });
      }
    } else {
      const competitionOrder = _.get(this.props, 'competitionOrder', []);
      let validChampionships = [...competitionOrder].reverse();
      championshipPositionValidity = [];

      for (let k = 0; k < validChampionships.length; k++) {
        const querySelector = `.${validChampionships[k]}-sticky-header.${
          isSafari || isFirefox
            ? 'fixed'
            : leftClasses[validChampionships.length - k - 1 - 1]
        }`;
        championshipPositionValidity.push(
          document.querySelector(querySelector),
        );
      }

      for (let l = 0; l < validChampionships.length; l++) {
        championship = championshipPositionValidity[l];
        const openStatusIndex =
          l < validChampionships.length - 1
            ? `last${validChampionships[l + 1]}`
            : `first${validChampionships[l]}`;
        if (championship && this.openSectionStatus[openStatusIndex]) {
          if (l !== validChampionships.length - 1) {
            if (isSafari || isEdge || isFirefox) {
              championship.classList.add('sticky');
              championship.classList.remove('fixed');
              championship.style.left = 'auto';
              championship.classList.add(rightClasses[l]);
            } else {
              championship.classList.remove(
                leftClasses[validChampionships.length - l - 1],
              );
              championship.classList.add(rightClasses[l]);
            }
          }
          break;
        }
      }
      // saves the new position for iteration.
      this.lastScrollLeft = scrollableSection.scrollLeft;

      if (
        scrollableSection.scrollLeft >=
        scrollableSection.scrollWidth - scrollableSection.clientWidth
      ) {
        this.setState({
          rightEndReached: true,
        });
      } else {
        this.setState({
          rightEndReached: false,
        });
      }

      if (scrollableSection.scrollLeft <= 0) {
        this.setState({
          leftEndReached: true,
        });
      } else {
        this.setState({
          leftEndReached: false,
        });
      }
    }
  };

  onClick = (e, totalCount, index, label) => {
    // Disable touch scroll
    this.tempDisableTouchScroll = true;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    let rightClasses, leftClasses, offsetSubtraction;
    offsetSubtraction = [50, 100, 150, 200, 250, 300, 350, 400, 450];
    rightClasses = [
      'pin-r',
      'pin-r-50',
      'pin-r-100',
      'pin-r-150',
      'pin-r-200',
      'pin-r-250',
      'pin-r-300',
      'pin-r-350',
      'pin-r-400',
    ];
    leftClasses = [
      'pin-l',
      'pin-l-50',
      'pin-l-100',
      'pin-l-150',
      'pin-l-200',
      'pin-l-250',
      'pin-l-300',
      'pin-l-350',
      'pin-l-400',
    ];

    const elements = document.querySelectorAll('.sticky-header');
    const targetElement = elements.item(index);

    if (isSafari || isEdge || isFirefox) {
      if (index === 0) {
        [...elements][0].classList.remove('sticky');
        [...elements][0].classList.add('fixed');

        if (_.get([...elements], '1')) {
          [...elements][1].classList.remove('fixed');
          [...elements][1].classList.add('sticky');
          [...elements][1].style.left = '';
          [...elements][1].classList.remove(leftClasses[index]);
          [...elements][1].classList.add(rightClasses[totalCount - index - 2]);
        }

        if (_.get([...elements], '2')) {
          [...elements][2].classList.remove('fixed');
          [...elements][2].classList.add('sticky');
          [...elements][2].style.left = '';
          [...elements][2].classList.remove(leftClasses[index + 1]);
          [...elements][2].classList.add(rightClasses[totalCount - index - 3]);
        }

        if (_.get([...elements], '3')) {
          [...elements][3].classList.remove('fixed');
          [...elements][3].classList.add('sticky');
          [...elements][3].style.left = '';
          [...elements][3].classList.remove(leftClasses[index + 2]);
          [...elements][3].classList.add(rightClasses[totalCount - index - 4]);
        }

        if (_.get([...elements], '4')) {
          [...elements][4].classList.remove('fixed');
          [...elements][4].classList.add('sticky');
          [...elements][4].style.left = '';
          [...elements][4].classList.remove(leftClasses[index + 3]);
          [...elements][4].classList.add(rightClasses[totalCount - index - 5]);
        }

        if (_.get([...elements], '5')) {
          [...elements][5].classList.remove('fixed');
          [...elements][5].classList.add('sticky');
          [...elements][5].style.left = '';
          [...elements][5].classList.remove(leftClasses[index + 4]);
          [...elements][5].classList.add(rightClasses[totalCount - index - 6]);
        }

        if (_.get([...elements], '6')) {
          [...elements][6].classList.remove('fixed');
          [...elements][6].classList.add('sticky');
          [...elements][6].style.left = '';
          [...elements][6].classList.remove(leftClasses[index + 5]);
          [...elements][6].classList.add(rightClasses[totalCount - index - 7]);
        }

        if (_.get([...elements], '7')) {
          [...elements][7].classList.remove('fixed');
          [...elements][7].classList.add('sticky');
          [...elements][7].style.left = '';
          [...elements][7].classList.remove(leftClasses[index + 6]);
          [...elements][7].classList.add(rightClasses[totalCount - index - 8]);
        }

        if (_.get([...elements], '8')) {
          [...elements][8].classList.remove('fixed');
          [...elements][8].classList.add('sticky');
          [...elements][8].style.left = '';
          [...elements][8].classList.remove(leftClasses[index + 7]);
          [...elements][8].classList.add(rightClasses[totalCount - index - 9]);
        }
      }
      if (index === 1) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '82px';
      } else if (index === 2) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '132px';
      } else if (index === 3) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '182px';
      } else if (index === 4) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '232px';
      } else if (index === 5) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '282px';
      } else if (index === 6) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '332px';
      } else if (index === 7) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '382px';
      } else if (index === 8) {
        targetElement.classList.remove('sticky');
        targetElement.classList.add('fixed');
        targetElement.style.left = '432px';
      }
    }

    [...elements].map((element, i) => {
      if (i < index) {
        if (isSafari || isFirefox) {
          if (i === 0) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
          }
          if (i === 1) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '82px';
          }
          if (i === 2) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '132px';
          }
          if (i === 3) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '182px';
          }
          if (i === 4) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '232px';
          }
          if (i === 5) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '282px';
          }
          if (i === 6) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '332px';
          }
          if (i === 7) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '382px';
          }
          if (i === 8) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '432px';
          }
        } else if (isEdge) {
          if (i === 1) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '82px';
          }
          if (i === 2) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '132px';
          }
          if (i === 3) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '182px';
          }
          if (i === 4) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '232px';
          }
          if (i === 5) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '282px';
          }
          if (i === 6) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '332px';
          }
          if (i === 7) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '382px';
          }
          if (i === 8) {
            element.classList.remove('sticky');
            element.classList.add('fixed');
            element.style.left = '432px';
          }
        }

        element.classList.remove(rightClasses[totalCount - i]);
        element.classList.add(leftClasses[i - 1]);
      } else if (i > index) {
        if (isSafari || isFirefox) {
          element.classList.remove('fixed');
          element.classList.add('sticky');
          element.style.left = '';
        } else if (isEdge) {
          element.classList.remove('fixed');
          element.classList.add('sticky');
          element.style.left = '';
        }

        element.classList.remove(leftClasses[i - 1]);
        element.classList.add(rightClasses[totalCount - i - 1]);
      }
      return null;
    });

    const scrollableSection = document.querySelector(
      '.saag-scrollable-section',
    );
    scrollableSection.scroll({
      left:
        [...elements][index].nextSibling.offsetLeft - offsetSubtraction[index],
      behavior: 'smooth',
    });

    if (index > 0) {
      this.setState({
        leftEndReached: false,
        rightEndReached: false,
      });
    } else {
      this.setState({
        leftEndReached: true,
      });
    }

    if (totalCount - 1 === index) {
      if (
        scrollableSection.scrollLeft >=
          scrollableSection.scrollWidth - scrollableSection.clientWidth - 136 ||
        scrollableSection.scrollLeft === 0
      ) {
        this.setState({
          rightEndReached: true,
        });
      } else {
        this.setState({
          rightEndReached: false,
        });
      }
    }

    // Re enable touch scroll after a second
    setTimeout(() => (this.tempDisableTouchScroll = false), 1000);
  };

  updateStatusState = (label, isFirstItem, status) => {
    const section = `${isFirstItem ? 'first' : 'last'}${label}`;
    setTimeout(
      () => (this.openSectionStatus[section] = status !== 'hidden'),
      500,
    );
  };

  onRightControlClick = (e) => {
    // Disable touch scroll
    this.tempDisableTouchScroll = true;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);
    let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const rightClasses = [
      'pin-r',
      'pin-r-50',
      'pin-r-100',
      'pin-r-150',
      'pin-r-200',
      'pin-r-250',
      'pin-r-300',
      'pin-r-350',
      'pin-r-400',
    ];
    const leftClasses = [
      'pin-l',
      'pin-l-50',
      'pin-l-100',
      'pin-l-150',
      'pin-l-200',
      'pin-l-250',
      'pin-l-300',
      'pin-l-350',
      'pin-l-400',
    ];
    const competitionOrder = _.get(this.props, 'competitionOrder', []);
    const validChampionships = [...competitionOrder];
    const championshipPositionValidity = [];
    const fixedLeftPostions = [
      '32px',
      '82px',
      '132px',
      '182px',
      '232px',
      '282px',
      '332px',
      '382px',
      '432px',
    ];
    const stickyHeaders = [...document.querySelectorAll('.sticky-header')];

    for (let i = 0; i < validChampionships.length; i++) {
      const querySelector = `.${validChampionships[i]}-sticky-header.${
        rightClasses[validChampionships.length - i - 1]
      }`;
      championshipPositionValidity.push(document.querySelector(querySelector));
    }

    for (let j = 0; j < validChampionships.length; j++) {
      let championship = championshipPositionValidity[j];

      if (
        championship &&
        this.openSectionStatus[`first${validChampionships[j]}`]
      ) {
        if (!isSafari || !isEdge || !isFirefox) {
          championship.classList.add(leftClasses[j - 1]);
          championship.classList.remove(
            rightClasses[validChampionships.length - j - 1],
          );
        }
        break;
      }
    }

    if (isSafari || isFirefox) {
      if (
        stickyHeaders[1] &&
        stickyHeaders[1].getBoundingClientRect().left <= 110
      ) {
        stickyHeaders[1].classList.remove('sticky');
        stickyHeaders[1].classList.add('fixed');
        stickyHeaders[1].style.left = fixedLeftPostions[1];
      }

      if (
        stickyHeaders[2] &&
        stickyHeaders[2].getBoundingClientRect().left <= 182
      ) {
        stickyHeaders[2].classList.remove('sticky');
        stickyHeaders[2].classList.add('fixed');
        stickyHeaders[2].style.left = fixedLeftPostions[2];
      }

      if (
        stickyHeaders[3] &&
        stickyHeaders[3].getBoundingClientRect().left <= 210
      ) {
        stickyHeaders[3].classList.remove('sticky');
        stickyHeaders[3].classList.add('fixed');
        stickyHeaders[3].style.left = fixedLeftPostions[3];
      }

      if (
        stickyHeaders[4] &&
        stickyHeaders[4].getBoundingClientRect().left <= 318
      ) {
        stickyHeaders[4].classList.remove('sticky');
        stickyHeaders[4].classList.add('fixed');
        stickyHeaders[4].style.left = fixedLeftPostions[4];
      }

      if (
        stickyHeaders[5] &&
        stickyHeaders[5].getBoundingClientRect().left <= 362
      ) {
        stickyHeaders[5].classList.remove('sticky');
        stickyHeaders[5].classList.add('fixed');
        stickyHeaders[5].style.left = fixedLeftPostions[5];
      }

      if (
        stickyHeaders[6] &&
        stickyHeaders[6].getBoundingClientRect().left <= 406
      ) {
        stickyHeaders[6].classList.remove('sticky');
        stickyHeaders[6].classList.add('fixed');
        stickyHeaders[6].style.left = fixedLeftPostions[6];
      }

      if (
        stickyHeaders[7] &&
        stickyHeaders[7].getBoundingClientRect().left <= 450
      ) {
        stickyHeaders[7].classList.remove('sticky');
        stickyHeaders[7].classList.add('fixed');
        stickyHeaders[7].style.left = fixedLeftPostions[7];
      }

      if (
        stickyHeaders[8] &&
        stickyHeaders[8].getBoundingClientRect().left <= 494
      ) {
        stickyHeaders[8].classList.remove('sticky');
        stickyHeaders[8].classList.add('fixed');
        stickyHeaders[8].style.left = fixedLeftPostions[8];
      }
    } else if (isEdge) {
      if (
        stickyHeaders[1] &&
        stickyHeaders[1].getBoundingClientRect().left <= 110
      ) {
        stickyHeaders[1].classList.remove('sticky');
        stickyHeaders[1].classList.add('fixed');
        stickyHeaders[1].style.left = fixedLeftPostions[1];
      }

      if (
        stickyHeaders[2] &&
        stickyHeaders[2].getBoundingClientRect().left <= 182
      ) {
        stickyHeaders[2].classList.remove('sticky');
        stickyHeaders[2].classList.add('fixed');
        stickyHeaders[2].style.left = fixedLeftPostions[2];
      }

      if (
        stickyHeaders[3] &&
        stickyHeaders[3].getBoundingClientRect().left <= 210
      ) {
        stickyHeaders[3].classList.remove('sticky');
        stickyHeaders[3].classList.add('fixed');
        stickyHeaders[3].style.left = fixedLeftPostions[3];
      }

      if (
        stickyHeaders[4] &&
        stickyHeaders[4].getBoundingClientRect().left <= 318
      ) {
        stickyHeaders[4].classList.remove('sticky');
        stickyHeaders[4].classList.add('fixed');
        stickyHeaders[4].style.left = fixedLeftPostions[4];
      }

      if (
        stickyHeaders[5] &&
        stickyHeaders[5].getBoundingClientRect().left <= 362
      ) {
        stickyHeaders[5].classList.remove('sticky');
        stickyHeaders[5].classList.add('fixed');
        stickyHeaders[5].style.left = fixedLeftPostions[5];
      }

      if (
        stickyHeaders[6] &&
        stickyHeaders[6].getBoundingClientRect().left <= 406
      ) {
        stickyHeaders[6].classList.remove('sticky');
        stickyHeaders[6].classList.add('fixed');
        stickyHeaders[6].style.left = fixedLeftPostions[6];
      }

      if (
        stickyHeaders[7] &&
        stickyHeaders[7].getBoundingClientRect().left <= 450
      ) {
        stickyHeaders[7].classList.remove('sticky');
        stickyHeaders[7].classList.add('fixed');
        stickyHeaders[7].style.left = fixedLeftPostions[7];
      }

      if (
        stickyHeaders[8] &&
        stickyHeaders[8].getBoundingClientRect().left <= 494
      ) {
        stickyHeaders[8].classList.remove('sticky');
        stickyHeaders[8].classList.add('fixed');
        stickyHeaders[8].style.left = fixedLeftPostions[8];
      }
    }

    const scrollableSection = document.querySelector(
      '.saag-scrollable-section',
    );
    const scrollStep = 136;
    const scrollAmount = scrollStep + scrollableSection.scrollLeft;
    scrollableSection.scroll({ left: scrollAmount, behavior: 'smooth' });

    if (
      scrollableSection.scrollLeft >=
      scrollableSection.scrollWidth - scrollableSection.clientWidth - scrollStep
    ) {
      this.setState({
        rightEndReached: true,
      });
    } else {
      this.setState({
        rightEndReached: false,
      });
    }

    if (scrollableSection.scrollLeft > 0 - scrollStep) {
      this.setState({
        leftEndReached: false,
      });
    } else {
      this.setState({
        leftEndReached: true,
      });
    }

    // Re enable touch scroll after a second
    setTimeout(() => (this.tempDisableTouchScroll = false), 1000);
  };

  onLeftControlClick = (e) => {
    // Disable touch scroll
    this.tempDisableTouchScroll = true;

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let isEdge = document.documentMode || /Edge/.test(navigator.userAgent);

    let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const rightClasses = [
      'pin-r',
      'pin-r-50',
      'pin-r-100',
      'pin-r-150',
      'pin-r-200',
      'pin-r-250',
      'pin-r-300',
      'pin-r-350',
      'pin-r-400',
    ];
    const leftClasses = [
      'pin-l',
      'pin-l-50',
      'pin-l-100',
      'pin-l-150',
      'pin-l-200',
      'pin-l-250',
      'pin-l-300',
      'pin-l-350',
      'pin-l-400',
    ];
    const competitionOrder = _.get(this.props, 'competitionOrder', []);
    const validChampionships = [...competitionOrder].reverse();
    const championshipPositionValidity = [];

    for (let i = 0; i < validChampionships.length; i++) {
      const querySelector = `.${validChampionships[i]}-sticky-header.${
        isSafari || isFirefox
          ? 'fixed'
          : leftClasses[validChampionships.length - i - 1 - 1]
      }`;
      championshipPositionValidity.push(document.querySelector(querySelector));
    }

    for (let j = 0; j < validChampionships.length; j++) {
      let championship = championshipPositionValidity[j];
      const openStatusIndex =
        j < validChampionships.length - 1
          ? `last${validChampionships[j + 1]}`
          : `first${validChampionships[j]}`;
      if (championship && this.openSectionStatus[openStatusIndex]) {
        if (j !== validChampionships.length - 1) {
          if (isSafari || isEdge || isFirefox) {
            championship.classList.add('sticky');
            championship.classList.remove('fixed');
            championship.style.left = 'auto';
            championship.classList.add(rightClasses[j]);
          } else {
            championship.classList.remove(
              leftClasses[validChampionships.length - j - 1],
            );
            championship.classList.add(rightClasses[j]);
          }
        }
        break;
      }
    }
    const scrollableSection = document.querySelector(
      '.saag-scrollable-section',
    );
    const scrollStep = 136;
    const scrollAmount = scrollableSection.scrollLeft - scrollStep;
    scrollableSection.scroll({ left: scrollAmount, behavior: 'smooth' });

    if (
      scrollableSection.scrollLeft >=
      scrollableSection.scrollWidth - scrollableSection.clientWidth + scrollStep
    ) {
      this.setState({
        rightEndReached: true,
      });
    } else {
      this.setState({
        rightEndReached: false,
      });
    }

    if (scrollableSection.scrollLeft <= 0 + scrollStep) {
      this.setState({
        leftEndReached: true,
      });
    } else {
      this.setState({
        leftEndReached: false,
      });
    }

    // Re enable touch scroll after a second
    setTimeout(() => (this.tempDisableTouchScroll = false), 1000);
  };

  getScoreShownStatus = (e) => {
    this.setState({
      ScoreProtectionDisabled: e,
    });
  };

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections, ScoreProtectionDisabled },
    } = this;

    const childrenWithProps = children.filter((child) => {
      return child !== '';
    });
    return (
      <div
        className={`w-auto flex flex-no-wrap w-full min-w-full max-w-full relative border-b border-medium-dark-grey h-80 overflow-hidden ${this.props.className}`}
      >
        <div className='left-scroll-control-wrapper absolute pin-t pin-l w-32'>
          <LeftScrollControl
            onClick={(e) => this.onLeftControlClick(e)}
            className={
              this.state.leftEndReached
                ? 'cursor-not-allowed control-disabled'
                : 'hover:bg-medium-grey cursor-pointer control-enabled'
            }
          />
        </div>
        <div className='saag-scrollable-section bg-widget-grey flex flex-no-wrap h-100 pb-20 overflow-x-auto overflow-y-hidden scrolling-auto ml-32 mr-128 relative'>
          {childrenWithProps.map((child, index) =>
            child && child.props.children ? (
              <CompetitionGroup
                isOpen={!!openSections[child.props.label]}
                label={child.props.label}
                key={index}
                totalCount={childrenWithProps.length}
                onClick={onClick}
                ScoreProtectionDisabled={ScoreProtectionDisabled}
                index={index}
                updateStatusState={this.updateStatusState}
              >
                {child.props.children}
              </CompetitionGroup>
            ) : null,
          )}
        </div>

        <div className='right-scroll-control-wrapper absolute pin-t pin-r mr-96 w-32'>
          <RightScrollControl
            onClick={(e) => this.onRightControlClick(e)}
            className={
              this.state.rightEndReached
                ? 'cursor-not-allowed control-disabled'
                : 'hover:bg-medium-grey cursor-pointer control-enabled'
            }
          />
        </div>

        <div className='score-protection-wrapper absolute pin-t pin-r w-96'>
          <ScoreProtection
            status={false}
            changed={(e) => this.getScoreShownStatus(e)}
          />
        </div>
      </div>
    );
  }
}

SaaGBarV2.propTypes = {
  /** Classes to apply to the root element of this component. Classes added here will override any default classes or props that accept classes. For example width - use with caution. */
  className: PropTypes.string,
  /** Whether to support multiple items to be opened simultaneously **/
  allowMultipleOpen: PropTypes.bool,
  /** Content for the accordion **/
  children: PropTypes.instanceOf(Object).isRequired,
  competitions: PropTypes.arrayOf(String),
};

SaaGBarV2.defaultProps = {
  allowMultipleOpen: true,
};

export default SaaGBarV2;
