import _ from 'lodash';
import React, { Component } from 'react';
// import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// import * as getSAAGData from '../../actions/saagData';

import SaaGBarV2 from './ui-kit/SaaG/SaaGBarV2';
import Fixture from './ui-kit/SaaG/Fixture';

import defaultConfig from '../../constants/defaultConfig';
import { Link } from 'react-router-dom';

class SaaGBarUI extends Component {
  // componentWillMount() {
  //   this.loadSAAGData();
  // }

  // componentDidMount() {
  //   this.timer = setInterval(() => this.loadSAAGData(), 60000);
  // }

  // componentWillUnmount() {
  //   if(this.timer) {
  //     clearInterval(this.timer);
  //   }
  // }

  // loadSAAGData = () => {
  //   this.props.getSAAGData.getSAAGData();
  // }

  gotoMatch = (url) => {
    // Do not allow subsequent clicks if already on match page
    const currentHref = window.location.href;
    if (currentHref.indexOf(url) === -1) {
      this.props.history.push(url);
    }
  };

  //         const elapsedMinutes = Math.abs(timeDiff.asMinutes());
  //         const matchPeriod = match.Period;
  //         let matchMinutes = elapsedMinutes;
  //         if (matchPeriod === 'First Half') {
  //           matchMinutes = 0 + elapsedMinutes;
  //         }
  //         if (matchPeriod === 'Second Half') {
  //           matchMinutes = 45 + elapsedMinutes;
  //         }
  //         if (matchPeriod === 'Extra First Half') {
  //           matchMinutes = 90 + elapsedMinutes;
  //         }
  //         if (matchPeriod === 'Extra Second Half') {
  //           matchMinutes = 105 + elapsedMinutes;
  //         }

  renderSaagFixtures = (data, comptype, legacySeason) => {
    const currentUser = _.get(this.props, 'user');
    const initObserver = _.get(currentUser, 'initObserver');
    const loggedIn = _.get(currentUser, 'loggedIn');
    const enableLiveHover = initObserver && loggedIn;

    const liveStartOverText = _.get(
      this.props,
      ['systemConfig', 'liveStartOverText'],
      'Rewind -3 Hrs',
    );

    // Temp.
    const competitionSeo =
      comptype === 'epl'
        ? 'epl'
        : comptype === 'ucl'
        ? 'uefa-champions-league'
        : comptype === 'uel'
        ? 'uefa-europa-league'
        : _.includes(['ec2020'], comptype)
        ? 'uefa-euro-2020'
        : comptype === 'ecq2020'
        ? 'internationals'
        : comptype === 'wwc'
        ? 'womens-world-cup-2023'
        : comptype === 'usc'
        ? 'uefa-champions-league'
        : comptype === 'wsl'
        ? 'womens'
        : comptype === 'jpjl'
        ? 'j-league'
        : comptype === 'kl'
        ? 'k-league'
        : comptype === 'fcwc'
        ? 'fifa-club-world-cup'
        : comptype === 'unl'
        ? 'internationals'
        : comptype === 'wqe'
        ? 'internationals'
        : comptype === 'coa'
        ? 'copa-america-2024'
        : comptype === 'fcw'
        ? 'womens'
        : comptype === 'dfb'
        ? 'dfb-pokal'
        : comptype === 'euc'
        ? 'uefa-euro-2024'
        : comptype === 'nwsl'
        ? 'nwsl'
        : '';
    if (data && !_.isEmpty(data)) {
      return data.map((match, i) => {
        const homeTeamShortcode =
          match.HomeTeamMetadataInfo && match.HomeTeamMetadataInfo.SYMID
            ? match.HomeTeamMetadataInfo.SYMID
            : match.HomeTeamName;
        const awayTeamShortcode =
          match.AwayTeamMetadataInfo && match.AwayTeamMetadataInfo.SYMID
            ? match.AwayTeamMetadataInfo.SYMID
            : match.AwayTeamName;
        const homeTeamImg =
          match.HomeTeamMetadataInfo &&
          match.HomeTeamMetadataInfo.FullTeamImageUrl
            ? match.HomeTeamMetadataInfo.FullTeamImageUrl
            : match.HomeTeamImageURL;
        const awayTeamImg =
          match.AwayTeamMetadataInfo &&
          match.AwayTeamMetadataInfo.FullTeamImageUrl
            ? match.AwayTeamMetadataInfo.FullTeamImageUrl
            : match.AwayTeamImageURL;
        const homeScore = match.HomeScore;
        const awayScore = match.AwayScore;
        const matchPeriod = match.Period;
        const matchStatus = match.MatchStatus;
        const isPre = _.includes(
          ['PreMatch', 'Abandoned', 'Postponed', 'Cancelled'],
          matchPeriod,
        );
        const type = isPre
          ? 'upcoming'
          : matchPeriod === 'Full Time'
          ? 'completed'
          : 'live';

        // Due to sports can now have multiple sports data, we need to check the match info instead
        const matchId = _.get(match, 'MatchID');
        let compId = _.get(match, 'CompetitionID');
        let seasonId =
          _.get(match, 'SeasonID') ||
          _.get(match, 'legacySeasonID') ||
          legacySeason;

        if (!compId) {
          // Temp.
          compId =
            comptype === 'epl'
              ? '8'
              : comptype === 'ucl'
              ? '5'
              : comptype === 'uel'
              ? '6'
              : comptype === 'unl'
              ? '941'
              : comptype === 'ecq2020'
              ? '235'
              : comptype === 'wwc'
              ? '408'
              : comptype === 'usc'
              ? '174'
              : comptype === 'wsl'
              ? '550'
              : comptype === 'jpjl'
              ? '20'
              : comptype === 'kl'
              ? '205'
              : comptype === 'fcwc'
              ? '335'
              : comptype === 'ec2020'
              ? '3'
              : comptype === 'wqe'
              ? '336'
              : comptype === 'coa'
              ? '128'
              : comptype === 'dfb'
              ? '231'
              : comptype === 'euc'
              ? '3'
              : comptype === 'nwsl'
              ? '832'
              : '';
        }
        if (!seasonId) {
          // Temp.
          seasonId =
            comptype === 'epl'
              ? '2020'
              : comptype === 'ucl'
              ? '2019'
              : comptype === 'uel'
              ? '2019'
              : comptype === 'unl'
              ? '2022'
              : comptype === 'ecq2020'
              ? '112020'
              : comptype === 'wwc'
              ? '2022'
              : comptype === 'usc'
              ? '2020'
              : comptype === 'wsl'
              ? '2020'
              : comptype === 'jpjl'
              ? '2020'
              : comptype === 'kl'
              ? '2020'
              : comptype === 'fcwc'
              ? '2020'
              : comptype === 'wqe'
              ? '112022'
              : comptype === 'ec2020'
              ? '2020'
              : comptype === 'coa'
              ? '2024 USA'
              : comptype === 'dfb'
              ? '2022'
              : comptype === 'euc'
              ? '2024'
              : comptype === 'nwsl'
              ? '2024'
              : '2020';
        }

        const matchDetailsURL = `/${competitionSeo}/play/?season=${seasonId}&competition=${compId}&match=${matchId}`;
        const gotoMatch = this.gotoMatch;
        const opta = this.props.opta;
        const startTimeEpochSeconds = match.trueStartTimeEpochSeconds * 1000;
        const startDate = moment(startTimeEpochSeconds)
          .local()
          .format('MMM D');
        const startTime = moment(startTimeEpochSeconds)
          .local()
          .format('HH:mm');

        const matchMinutes = parseInt(
          Math.floor(match.liveTimeElapsedSeconds / 60),
        );

        return (
          <Fixture
            homeTeamShortcode={homeTeamShortcode}
            awayTeamShortcode={awayTeamShortcode}
            homeTeamImg={homeTeamImg}
            awayTeamImg={awayTeamImg}
            homeScore={homeScore}
            awayScore={awayScore}
            type={type}
            matchDetailsURL={matchDetailsURL}
            gotoMatch={gotoMatch}
            opta={opta}
            matchStatus={matchStatus}
            matchPeriod={matchPeriod}
            matchMinutes={matchMinutes}
            startDate={startDate}
            startTime={startTime}
            key={i}
            enableLiveHover={enableLiveHover}
            liveStartOverText={liveStartOverText}
          />
        );
      });
    } else {
      return [
        <div
          key='1'
          className={`
              border-medium-grey
              border-r
              flex
              flex-col
              font-MarkProBold
              px-16
              py-22
              text-center
              text-xs
              w-296
            `}
        >
          <span>No games this week.</span>
          <span>
            <Link
              to={`/${competitionSeo}/fixtures`}
              className={`
                    font-MarkProBold
                    leading-lg
                    text-teal
                    os-transition-btn
                    hover:text-teal-dark
                    active:text-teal-light
                  `}
            >
              View Fixtures
            </Link>
          </span>
        </div>,
        <div key='2' />,
      ];
    }
  };

  sortCompetitionByLiveAndClosestFixture(competitionData) {
    let competitionOrder = [];
    let firstCompetitionIndexNoMatches;
    let pinToDetected = false;

    const isLiveMatch = (match) => {
      const matchPeriod = _.get(match, 'Period', '');
      const isPre = _.includes(
        ['PreMatch', 'Abandoned', 'Postponed'],
        matchPeriod,
      );
      const type = isPre
        ? 'upcoming'
        : matchPeriod === 'Full Time' || matchPeriod === 'Cancelled'
        ? 'completed'
        : 'live';
      return type === 'live';
    };

    _.each(competitionData, (competitionInfo, competitionId) => {
      const matches = _.get(competitionInfo, 'data');
      const enabled = _.get(competitionInfo, 'enabled');
      const pinTo = _.get(competitionInfo, ['metadata', 'pinTo'], null);
      const legacySeason = _.get(
        competitionInfo,
        ['metadata', 'legacySeason'],
        null,
      );

      if (enabled) {
        // Firstly check for live games
        let numberOfLiveGames = 0;
        _.each(matches, (match) => {
          if (isLiveMatch(match)) {
            numberOfLiveGames++;
          }
        });

        const closestMatch = _.minBy(matches, 'trueStartTimeEpochSeconds');
        const trueStartTimeEpochSeconds = _.get(
          closestMatch,
          'trueStartTimeEpochSeconds',
        );
        const competitionObj = {
          id: competitionId,
          matches,
          liveGamesCount: numberOfLiveGames,
          // No start time so use a large value for trueStartTimeEpochSeconds to push to end of array
          trueStartTimeEpochSeconds: trueStartTimeEpochSeconds
            ? trueStartTimeEpochSeconds
            : 9999999999,
          pinTo,
          legacySeason,
        };

        if (!pinToDetected && pinTo !== null) {
          pinToDetected = true;
        }

        competitionOrder.push(competitionObj);
      }
    });

    // Now sort by highest live games count then lowest true start time value
    function compareLiveGamesAndStartTimeEpochSeconds(a, b) {
      if (a.liveGamesCount !== 0 || b.liveGamesCount !== 0) {
        if (a.liveGamesCount > b.liveGamesCount) return -1;
        if (a.liveGamesCount < b.liveGamesCount) return 1;
      } else {
        if (a.trueStartTimeEpochSeconds < b.trueStartTimeEpochSeconds)
          return -1;
        if (a.trueStartTimeEpochSeconds > b.trueStartTimeEpochSeconds) return 1;
      }
      return 0;
    }
    const sortedCompetitionOrder = competitionOrder.sort(
      compareLiveGamesAndStartTimeEpochSeconds,
    );

    sortedCompetitionOrder.forEach((competition, index) => {
      let { matches } = competition;

      if (!firstCompetitionIndexNoMatches) {
        if (_.isEmpty(matches)) {
          firstCompetitionIndexNoMatches = index;
        }
      }

      // Filter out matches which are still in "PreMatch" period  AND have started 2 hours ago
      const filteredMatches = matches.filter(
        (match) =>
          !(
            match.Period === 'PreMatch' &&
            moment
              .unix(match.trueStartTimeEpochSeconds + 2 * 60 * 60)
              .isBefore()
          ),
      );

      const liveMatches = filteredMatches.filter((match) => isLiveMatch(match));

      // Move live matches to the beginning for each competition
      liveMatches.forEach((match) => {
        const index = filteredMatches.indexOf(match);
        filteredMatches.splice(index, 1);
      });
      competition.matches = [...liveMatches, ...filteredMatches];
    });

    // 16-Jun-2020: Now we will pin certain competitions based on BE sportsdata "pinTo" value overrides (metadata > pinTo)
    let pinnedSortedCompetitionOrder = _.merge([], sortedCompetitionOrder);
    if (pinToDetected) {
      sortedCompetitionOrder.forEach((competition) => {
        let { pinTo, id, matches, liveGamesCount } = competition;

        if (pinTo !== null) {
          let realIndex =
            pinTo === 'start' ? 0 : pinnedSortedCompetitionOrder.length;

          // We will pin the 'end' onto index where the first empty competition occurs if the
          // current competition has matches
          // NOTE: We should not override and pin to end if we detect there are live games
          if (pinTo === 'end' && liveGamesCount === 0) {
            if (firstCompetitionIndexNoMatches && !_.isEmpty(matches)) {
              realIndex = firstCompetitionIndexNoMatches;
            }
          }

          // Mark original competition for removal post sorting
          const pinnedCompetition = _.find(
            pinnedSortedCompetitionOrder,
            (comp) => _.get(comp, 'id') === id,
          );
          if (pinnedCompetition) {
            _.set(pinnedCompetition, 'delete', true);
          }

          pinnedSortedCompetitionOrder.splice(realIndex, 0, competition);
        }
      });

      // Remove any duplicated original competitions
      _.remove(
        pinnedSortedCompetitionOrder,
        (comp) => _.get(comp, 'delete') === true,
      );
    }

    return pinnedSortedCompetitionOrder || sortedCompetitionOrder;
  }

  render() {
    let EPLData,
      UCLData,
      UELData,
      UNLData,
      ECQ2020Data,
      INTData,
      WWCData,
      USCdata,
      UCLQData,
      WSLData,
      FCWCData,
      JPJLData,
      EC2020Data,
      KLData,
      WQEData,
      COAData,
      FCWData,
      DFBData,
      EUCData,
      NWSLData;

    const saagData = this.props.saagdata;

    if (saagData && saagData !== undefined && saagData.length) {
      const eplData = saagData.find((item) => item.sport === 'epl');
      EPLData = eplData && eplData.info ? eplData.info : [];

      const uclData = saagData.find((item) => item.sport === 'ucl');
      UCLData = uclData && uclData.info ? uclData.info : [];

      const uclqData = saagData.find((item) => item.sport === 'uclq');
      UCLQData = uclqData && uclqData.info ? uclqData.info : [];

      UCLData = [...UCLData, ...UCLQData];

      const uelData = saagData.find((item) => item.sport === 'uel');
      UELData = uelData && uelData.info ? uelData.info : [];

      const unlData = saagData.find((item) => item.sport === 'unl');
      UNLData = unlData && unlData.info ? unlData.info : [];

      const ecq2020Data = saagData.find((item) => item.sport === 'ecq2020');
      ECQ2020Data = ecq2020Data && ecq2020Data.info ? ecq2020Data.info : [];

      const intData = saagData.find((item) => item.sport === 'int');
      INTData = intData && intData.info ? intData.info : [];

      const wwcData = saagData.find((item) => item.sport === 'wwc');
      WWCData = wwcData && wwcData.info ? wwcData.info : [];

      const uscData = saagData.find((item) => item.sport === 'usc');
      USCdata = uscData && uscData.info ? uscData.info : [];

      const wslData = saagData.find((item) => item.sport === 'wsl');
      WSLData = wslData && wslData.info ? wslData.info : [];

      const fcwcData = saagData.find((item) => item.sport === 'fcwc');
      FCWCData = fcwcData && fcwcData.info ? fcwcData.info : [];

      const jpjlData = saagData.find((item) => item.sport === 'j-league');
      JPJLData = jpjlData && jpjlData.info ? jpjlData.info : [];

      const ec2020Data = saagData.find((item) => item.sport === 'ec2020');
      EC2020Data = ec2020Data && ec2020Data.info ? ec2020Data.info : [];

      const klData = saagData.find((item) => item.sport === 'k-league');
      KLData = klData && klData.info ? klData.info : [];

      const wqeData = saagData.find((item) => item.sport === 'wqe');
      WQEData = wqeData && wqeData.info ? wqeData.info : [];

      const coaData = saagData.find((item) => item.sport === 'coa');
      COAData = coaData && coaData.info ? coaData.info : [];
      const fcwData = saagData.find((item) => item.sport === 'fcw');
      FCWData = fcwData && fcwData.info ? fcwData.info : [];

      const dfbData = saagData.find((item) => item.sport === 'dfb');
      DFBData = dfbData && dfbData.info ? dfbData.info : [];

      const eucData = saagData.find((item) => item.sport === 'euc');
      EUCData = eucData && eucData.info ? eucData.info : [];

      const nwslData = saagData.find((item) => item.sport === 'nwsl');
      NWSLData = nwslData && nwslData.info ? nwslData.info : [];

      const eplEnabled = defaultConfig.saagCompetitions.indexOf('EPL') > -1;
      const uclEnabled = defaultConfig.saagCompetitions.indexOf('UCL') > -1;
      const uelEnabled = defaultConfig.saagCompetitions.indexOf('UEL') > -1;
      const unlEnabled = defaultConfig.saagCompetitions.indexOf('UNL') > -1;
      const ecq2020Enabled =
        defaultConfig.saagCompetitions.indexOf('ECQ2020') > -1;
      const intEnabled = defaultConfig.saagCompetitions.indexOf('INT') > -1;
      const wwcEnabled = defaultConfig.saagCompetitions.indexOf('WWC') > -1;
      const uscEnabled = defaultConfig.saagCompetitions.indexOf('USC') > -1;
      const wslEnabled = defaultConfig.saagCompetitions.indexOf('WSL') > -1;
      const fcwcEnabled = defaultConfig.saagCompetitions.indexOf('FCWC') > -1;
      const jpjlEnabled = defaultConfig.saagCompetitions.indexOf('JPJL') > -1;
      const ec2020Enabled =
        defaultConfig.saagCompetitions.indexOf('EC2020') > -1;
      const klEnabled = defaultConfig.saagCompetitions.indexOf('KL') > -1;
      const wqeEnabled = defaultConfig.saagCompetitions.indexOf('WQE') > -1;
      const coaEnabled = defaultConfig.saagCompetitions.indexOf('COA') > -1;
      const fcwEnabled = defaultConfig.saagCompetitions.indexOf('FCW') > -1;
      const dfbEnabled = defaultConfig.saagCompetitions.indexOf('DFB') > -1;
      const eucEnabled = defaultConfig.saagCompetitions.indexOf('EUC') > -1;
      const nwslEnabled = defaultConfig.saagCompetitions.indexOf('NWSL') > -1;

      const competitionsOrder = this.sortCompetitionByLiveAndClosestFixture({
        EPL: { data: EPLData, enabled: eplEnabled, metadata: eplData },
        UCL: { data: UCLData, enabled: uclEnabled, metadata: uclData },
        UEL: { data: UELData, enabled: uelEnabled, metadata: uelData },
        UNL: { data: UNLData, enabled: unlEnabled, metadata: unlData },
        ECQ2020: {
          data: ECQ2020Data,
          enabled: ecq2020Enabled,
          metadata: ecq2020Data,
        },
        INT: { data: INTData, enabled: intEnabled, metadata: intData },
        WWC: { data: WWCData, enabled: wwcEnabled, metadata: wwcData },
        USC: { data: USCdata, enabled: uscEnabled, metadata: uscData },
        WSL: { data: WSLData, enabled: wslEnabled, metadata: wslData },
        FCWC: { data: FCWCData, enabled: fcwcEnabled, metadata: fcwcData },
        JPJL: { data: JPJLData, enabled: jpjlEnabled, metadata: jpjlData },
        EC2020: {
          data: EC2020Data,
          enabled: ec2020Enabled,
          metadata: ec2020Data,
        },
        KL: { data: KLData, enabled: klEnabled, metadata: klData },
        WQE: { data: WQEData, enabled: wqeEnabled, metadata: wqeData },
        COA: { data: COAData, enabled: coaEnabled, metadata: coaData },
        FCW: { data: FCWData, enabled: fcwEnabled, metadata: fcwData },
        DFB: { data: DFBData, enabled: dfbEnabled, metadata: dfbData },
        EUC: { data: EUCData, enabled: eucEnabled, metadata: eucData },
        NWSL: { data: NWSLData, enabled: nwslEnabled, metadata: nwslData },
      });

      const competitionOrderSimple = _.map(competitionsOrder, (competition) => {
        return competition.id;
      });

      return (
        <SaaGBarV2 competitionOrder={competitionOrderSimple}>
          {_.map(competitionsOrder, (competition) => {
            const { id, matches, legacySeason } = competition;
            return (
              <div key={id} label={id}>
                {this.renderSaagFixtures(
                  matches,
                  id.toLowerCase(),
                  legacySeason,
                )}
              </div>
            );
          })}
        </SaaGBarV2>
      );
    }

    return null;
  }
}

function mapStateToProps(state) {
  const { saagdata, systemConfig, user } = state;

  return {
    saagdata,
    systemConfig,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getSAAGData: bindActionCreators(getSAAGData, dispatch)
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SaaGBarUI),
);
